<template>
  <div class="item-roya">
    <!-- start 条件过滤区 -->
    <div class="df-row">
      <!-- 门店选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.class_guid"
          placeholder="请选择分类"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in classList"
            :key="item.ClassGuid"
            :label="item.ClassName"
            :value="item.ClassGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.show_guid"
          placeholder="请选择显示组"
          size="mini"
          clearable
          @change="onChangeTree"
        >
          <el-option
            v-for="item in treeData"
            :key="item.ShowGuid"
            :label="`${item.Prefix + item.ShowName}`"
            :value="item.ShowGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询文本框 -->
      <div class="df-col">
        <el-input v-model="submitData.word" size="mini" />
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearchData">
          查询
        </el-button>
      </div>

      <!-- 批量设置按钮 -->
      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          :disabled="selectItems.length == 0"
          @click="onShowRuleDialog({}, -1, 'batch')"
        >
          批量设置
        </el-button>
      </div>

      <div v-show="authType == 'H'" class="df-col">
        <el-button type="success" size="mini" @click="onShowSync"
          >同步项目提成</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区 -->

    <!-- start 项目提成表 -->
    <el-table
      ref="ElTable"
      v-loading="loading"
      :data="settingList"
      border
      stripe
      :max-height="tableMaxHeight"
    >
      <el-table-column width="50">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            v-model="isActiveCheck"
            :checked="allCheck"
            :indeterminate="indeterminate"
            @change="onSelectChange('all', settingList)"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            :value="scope.row.isCheck"
            :checked="scope.row.isCheck"
            :indeterminate="false"
            @change="onSelectChange('', scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="ItemNo"
        label="项目编号"
        :width="adaptiveDefault.tableColumnWidth.itemNo"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ItemName"
        label="项目名称"
        :width="adaptiveDefault.tableColumnWidth.itemName"
        align="center"
      >
        <template slot-scope="scope">
          <div class="item">
            <span>{{ scope.row.ItemName }}</span>
            <span>{{ scope.row.Price }}元</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ClassName"
        label="分类名称"
        :width="adaptiveDefault.tableColumnWidth.className"
        align="center"
      ></el-table-column>
      <el-table-column label="提成规则" align="center" :width="adaptiveDefault.tableColumnWidth.royaRule">
        <template slot-scope="scope">
          <div class="rule-list">
            <div
              class="rule-item"
              v-for="(item, index) in scope.row.RuleTextList"
              :key="index"
            >
              <div class="text">
                <span style="color: tomato;" v-show="item.HeadMark">{{
                  "[总] "
                }}</span>
                {{ item.DisplayText }}
              </div>
              <div class="span-box">
                <span @click="onShowRuleDialog(scope.row, index)">[修改]</span>
                <span @click="deleteSetting(item)">[删除]</span>
              </div>
            </div>

            <div class="span-box">
              <span @click="onShowRuleDialog(scope.row)">[增加]</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 项目提成表 -->

    <!-- start 分页器 -->
    <div class="pagination-box">
      <el-button type="primary" size="small" @click="onChangePagination"
        >{{ !isPagination ? "" : "不" }}分页</el-button
      >
      <el-pagination
        background
        :page-sizes="[100, 200, 300, 400, 10000]"
        :page-size="submitData.page_size"
        :current-page="submitData.page_index"
        :layout="
          !isPagination ? 'total' : 'total, sizes, prev, pager, next, jumper'
        "
        :total="totalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- end 分页器 -->

    <CommissionSettingDialog
      ref="CommissionSettingDialog"
      :selectItems="itemSelectList"
      @success="onSaveSuccess"
    ></CommissionSettingDialog>

    <DfSyncStoreDialog
      ref="DfSyncStoreDialog"
      :syncFuction="synItemRoya"
    ></DfSyncStoreDialog>
  </div>
</template>

<script>
import Item from "@/api/item.js";
import Service from "@/api/service.js";

import CommissionSettingDialog from "./components/commission-setting-dialog.vue";

export default {
  components: { CommissionSettingDialog },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      authType: "",
      scrollTop: 0,
      submitData: {
        class_guid: "", // 分类guid
        show_guid: "",
        word: "", // 关键字
        page_index: 1, // 页码
        page_size: 200, // 页数
      }, // 提交数据
      totalRecord: 0, // 总数
      settingList: [], // 设置数据列表
      classList: [], // 分类列表
      selectionList: [],
      itemSelectList: [],
      loading: false,

      requestPowerParam: {
        // 控制权限的对话框
        show: false,
        key: "",
        code: "",
        power: "",
        callback: () => {},
      },
      treeData: [],
      selectItems: [],
      cardGuids: [],
      synRefsTypies: [],
      isPagination: true,
      isActiveCheck: false,
      allCheck: false,
      indeterminate: false,

      mediaWidthAdaptive: {
        "1000": {
          tableColumnWidth: {
            itemNo: "80px",
            itemName: "120px",
            className: "100px",
            royaRule: "",
          },
        },
        "1280": {
          tableColumnWidth: {
            itemNo: "80px",
            itemName: "140px",
            className: "120px",
            royaRule: "",
          },
        },
        "1920": {
          tableColumnWidth: {
            itemNo: "100px",
            itemName: "200px",
            className: "140px",
            royaRule: "",
          },
        },
      },
      adaptiveDefault: {
        tableColumnWidth: {
          itemNo: "",
          itemName: "",
          className: "",
          royaRule: "",
        },
      },
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;

    this.getItemshowFlat();
    this.getItemclass();
    this.getSettingList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight, clientWidth } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 52);

      for (let key in this.mediaWidthAdaptive) {
        // console.log(Number(key));
        if (clientWidth <= Number(key)) {
          this.adaptiveDefault = {};
          this.adaptiveDefault = JSON.parse(
            JSON.stringify(this.mediaWidthAdaptive[key])
          );
          break;
        }
      }
    },

    // 获取项目分类列表
    async getItemclass() {
      try {
        let { data } = await Service.getItemclass();
        this.classList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取所有项目分类组
    async getItemshowFlat() {
      try {
        let { data } = await Item.getItemshowFlat();
        this.treeData = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取设置列表
    async getSettingList(itemGuids = []) {
      this.loading = true;
      this.settingList = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;

      try {
        let { data } = await Item.getSettingList(this.submitData);
        this.totalRecord = data.filter.TotalRecord;
        if (itemGuids.length > 0) {
          this.isActiveCheck = true;
          this.onSelectChange("all", data.list);
          return;
        } else if (this.selectItems.length > 0) {
          this.settingList = data.list;
          this.onRowSelection();
          return;
        }
        this.settingList = data.list;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 批量保存设置
    async saveBatchSetting() {
      let submitData = {
        Data: [],
        List: [{}],
      };
      try {
        let { errcode } = await Item.saveBatchSetting(submitData);
      } catch (err) {
        console.log(err);
      }
    },

    // 删除设置
    async deleteSetting(event) {
      try {
        let { errcode } = await Item.deleteSetting({
          roya_guid: event.RoyaGuid,
        });
        if (errcode == 0) {
          this.$message.success("删除成功");
          this.getSettingList();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 同步项目提成
    async synItemRoya(event) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems));
      try {
        let res = await Item.synItemRoya(event.authKey, event.authCode, {
          SourceStoresGuid: window.sessionStorage.getItem("selectStoreGuid"),
          TargetStoresGuid: event.targetStoresGuid,
          SelectItemGuids: selectItems.map((e) => e.ItemGuid),
        });
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },

    // 搜索按钮点击事件
    onSearchData() {
      this.submitData.page_index = 1;
      this.getSettingList();
    },

    // 打开项目提成设置对话框
    onShowRuleDialog(event = {}, index = -1, type = "single") {
      let submitData = {};
      event.RuleTextList && index > -1
        ? (submitData = { ...event.RuleTextList[index] })
        : "";
      submitData = {
        ItemGuid: event.ItemGuid,
        RoyaGuid: submitData.RoyaGuid || "",
        // ...submitData,
      };
      // console.log(this.selectItems);
      this.itemSelectList = this.selectItems.map((e) => {
        // if (e.isCheck) {
        return { ItemGuid: e.ItemGuid, ItemName: e.ItemName };
        // }
      });
      // console.log(submitData);
      this.$refs.CommissionSettingDialog.onShowDialog({
        items: submitData,
        type,
      });
    },

    onShowSync() {
      this.util.checkPower("1007", this.requestPowerParam, async () => {
        let arr = await this.selectItems.map((e) => e.ItemGuid);
        if (!arr.length) {
          return this.$message.error("请先选择会员卡");
        }
        // this.cardGuids = arr;
        await this.$refs.DfSyncStoreDialog.onShowDialog(arr);
      });
    },

    // 多选框改变事件
    async onSelectChange(event = "", row) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems)),
        settingList = JSON.parse(
          JSON.stringify(event === "" ? this.settingList : row)
        );
      this.loading = true;
      if (event == "all") {
        selectItems = [];
        await settingList.forEach((e) => {
          if (this.isActiveCheck) {
            e.isCheck = true;
            selectItems.push(e);
          } else {
            selectItems = [];
            e.isCheck = false;
          }
        });
        this.settingList = await settingList;
      } else {
        let index = await selectItems.findIndex(
            (e) => e.ItemGuid == row.ItemGuid
          ),
          sourceIndex = await settingList.findIndex(
            (e) => e.ItemGuid == row.ItemGuid
          );
        index > -1
          ? selectItems.splice(index, 1)
          : (selectItems = [...selectItems, row]);
        settingList[sourceIndex].isCheck = await !row.isCheck;
        this.settingList = await settingList;
        // row.isCheck = !row.isCheck;
      }

      this.selectItems = await selectItems;
      await this.onAllSelection();
      await setTimeout(() => {
        this.loading = false;
      }, 200);
      // this.selectItems = [...this.selectItems, row]
      // console.log(this.selectItems);
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(async () => {
        let count = 0;
        // console.log(this.settingList, this.selectItems);
        await this.selectItems.forEach((item) => {
          for (let index = 0; index < this.settingList.length; index++) {
            if (this.settingList[index].ItemGuid === item.ItemGuid) {
              count++;
              // console.log(this.settingList[index], item);
              // this.$refs.multipleTable.toggleRowSelection(
              //   this.settingList[index],
              //   true
              // );
              this.settingList[index].isCheck = true;
              break;
            }
          }
        });

        if (count > 0) {
          await this.onAllSelection();
        } else {
          this.isActiveCheck = false;
          this.allCheck = false;
          this.indeterminate = false;
        }
      });
    },

    // 全选回显
    async onAllSelection() {
      this.isActiveCheck = (await this.selectItems.length) > 0 ? true : false;
      this.allCheck =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length == this.settingList.length
          ? true
          : false;
      this.indeterminate =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length != this.settingList.length
          ? true
          : false;
    },

    onChangeTree() {},

    onChangePagination() {
      this.handleSizeChange(this.isPagination ? 10000 : 10);
      this.isPagination = !this.isPagination;
    },

    // // 表格多选项改变事件
    // async onChangeSelection(event, row) {
    //   // console.log(event, row);
    //   if (
    //     event.length > 0 &&
    //     event[event.length - 1].ItemGuid == row.ItemGuid
    //   ) {
    //     let arr = [];
    //     // if (row.RuleTextList.length) {
    //     //   await row.RuleTextList.forEach((item, index) => {
    //     //     arr[index] = {
    //     //       ItemGuid: row.ItemGuid,
    //     //       ItemName: row.ItemName,
    //     //       RoyaGuid: item.RoyaGuid,
    //     //     };
    //     //   });
    //     // } else {
    //     await arr.push({
    //       ItemGuid: row.ItemGuid,
    //       ItemName: row.ItemName,
    //       // RoyaGuid: "",
    //     });
    //     // }

    //     this.selectItems = await [...this.selectItems, ...arr];
    //   } else {
    //     await this.selectItems.find(async (item, index) => {
    //       if (item.ItemGuid == row.ItemGuid) {
    //         await this.selectItems.splice(index, 1);
    //         return;
    //       }
    //     });
    //   }

    //   await this.$nextTick(() => {
    //     this.selectionList = event;
    //   });
    // },

    // // 表格多选项全选改变事件
    // async onChangeAllSelection(event) {
    //   let arr = [];

    //   this.selectionList = event;

    //   if (!event.length) {
    //     return (this.selectItems = []);
    //   }

    //   await event.forEach(async (items, itemIndex) => {
    //     // let object = {
    //     //   ItemGuid: items.ItemGuid,
    //     //   ItemName: items.ItemName,
    //     //   RoyaGuid: "",
    //     // };

    //     // if (items.RuleTextList.length) {
    //     //   await items.RuleTextList.forEach(async (rules, ruleIndex) => {
    //     //     object.RoyaGuid = await rules.RoyaGuid;
    //     //     arr[itemIndex] = await object;
    //     //   });
    //     // } else {
    //     //   arr[itemIndex] = await object;
    //     // }

    //     await arr.push({
    //       ItemGuid: items.ItemGuid,
    //       ItemName: items.ItemName,
    //     });
    //   });

    //   await this.$nextTick(async () => {
    //     // await console.log(arr);
    //     this.selectItems = await arr;
    //   });
    // },

    // 保存成功返回事件
    async onSaveSuccess() {
      this.itemSelectList = [];
      this.scrollTop = await this.$refs.ElTable.bodyWrapper.scrollTop;
      // console.log(this.scrollTop);
      // this.selectItems = [];
      await this.getSettingList();
      this.$refs.ElTable.bodyWrapper.scrollTop = await this.scrollTop;
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      console.log(event);
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getSettingList();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getSettingList();
    },
  },
};
</script>

<style lang="less" scoped>
.item-roya {
  padding-top: 20px;
  box-sizing: border-box;

  .el-table {
    .rule-list {
      .flex-col;
      .rule-item {
        .flex-row;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    .span-box {
      color: #409eff;
      span {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .item {
      .flex-col;
    }
  }
}
.pagination-box {
  .flex-row;
  justify-content: flex-end;
  margin-top: 20px;

  .el-button {
    margin-right: 20px;
  }
}
</style>
